import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/vincent/Documents/Develop/Web/PersonalWebsite/website/src/components/layout-markdown.tsx";
import ExternalLink from "../components/external-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`I'm available as a software consultant to help you develop faster sustainably. At the beginning of a project, I help you identify the things to get right to minimize the chances of blockers in the evolution of your product, and to keep the codebase a joy to work on. We'll explore hard problems you're solving, `}<a parentName="p" {...{
        "href": "/blog/team-conversations-tech/"
      }}>{`talk about engineering culture`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.vdenboer.com/blog/scenario-replays/"
      }}>{`techniques that make work easier to distribute`}</a>{` and how to create understanding and foster collaboration across different teams. For existing products, I help you to identify blockers to keep you from growing as fast as you could, and solve complex problems you might be facing.`}</p>
    <p>{`While I love writing code, the code I write normally serves as a base for others to work upon, showing how to solve particular problems, creating a skeleton that allows work to be easily distributed or showing certain patterns we'll be using during furher development. However, full-time coding roles are not the best way to work with me.`}</p>
    <p>{`Over the last years as CTO of WorldBrain I've helped to build `}<ExternalLink href="https://memex.garden/" mdxType="ExternalLink">{`Memex`}</ExternalLink>{` in such a way that with a 2-developer team we were able to`}</p>
    <ol>
  <li>
    launch an elegant, simple and privacy-focused multi-client
    database sync mechanism
  </li>
  <li>
    transition the whole product from local-first to cloud-based in a
    very short time-frame
  </li>
  <li>
    transition a big chunk of business logic from the browser
    extension to the mobile app
  </li>
  <li>
    develop forward-thinking development workflows that allowed easy
    outsourcing of styling work and reduce daily iteration times.
  </li>
    </ol>
    <p>{`Also, I've been `}<a parentName="p" {...{
        "href": "/katachi/presentation/"
      }}>{`working on a technology`}</a>{` that allows`}</p>
    <ol>
      <li parentName="ol">{`engineers to build design systems and complete UIs shared across different products and teams using different technologies`}</li>
      <li parentName="ol">{`designers to directly edit the end product through a visual editor, saving them the onboarding costs to different frameworks and development styles.`}</li>
      <li parentName="ol">{`easily trial and implement best practices like `}<a parentName="li" {...{
          "href": "https://spectrum.adobe.com/page/design-tokens/"
        }}>{`design tokens`}</a>{` with custom checks, allowing for automatic detection and fixing of best practice deviation across the entire design system`}</li>
    </ol>
    <p>{`Does it sound like I could be useful to you? Let's start talking at `}<a parentName="p" {...{
        "href": "mailto:hello@youapt.eu"
      }}>{`hello@youapt.eu`}</a>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      